@media all and (max-width: 991px) {
  .lead-wrapper.users {
    .small {
      width: 45%;
    }
  }
}

@media all and (max-width: 820px) {
  body {
    background: #fff;
  }

  nav .toggle-menu {
    background: #fff;
  }

  section {
    box-shadow: none;

    &.login {
      text-align: center;
    }
  }

  .side-nav {
    section {
      border-top: 2px solid $purple;
      border-bottom: 2px solid $purple;
    }
  }

  .lead-wrapper {
    border-top: 5px solid #ec048c;
    background: #f3f3f3;
    margin-top: 10px;

    .lead-container {
      box-shadow: 0px 14px 2px -4px #dadada;
    }
  }
}

@media all and (max-width: 767px) {
  form {
    .form-group {
      width: 50%;
    }
  }
}

@media all and (max-width: 650px) {
  nav {
    &.mobile {
      .link-btn {
        text-align: right;

        &.link-btn-light a {
          font-size: 1.2em;
          background: none !important;
          color: #696b6c !important;
          border-radius: 0;
          border: none;
          border-top: 2px solid;
          border-bottom: 2px solid;
          border-right: 2px solid;
        }

        &.link-btn-danger a {
          font-size: 1.2em;
          background: none !important;
          color: #ec028c !important;
          border-radius: 0;
          border: none;
          border-top: 2px solid;
          border-bottom: 2px solid;
          border-right: 2px solid;
        }

        &.active {
          a {
            color: #6d38c1 !important;
          }
        }
      }
      &.expanded {
        .nav-container {
          flex-flow: column;
          align-items: flex-end;
        }
      }
    }

    .toggle-menu {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-end;
      position: static;
      border: none;
      box-shadow: none;

      h6 {
        width: 100%;
      }
    }
  }
}

@media all and (max-width: 575px) {
  .lead-wrapper {
    .lead-collapse {
      background: #fff;
    }

    hr {
      display: none;
    }

    .row {
      margin-bottom: 0 !important;

      & > div {
        padding: 10px 0 5px;
        border-bottom: 2px solid #f9f9f9;

        &.text-right:not(.expandable) {
          text-align: left !important;
        }

        &.expandable {
          border-bottom: none;
          button {
            background: #d2d2d2;
            border-radius: 25px;
            padding: 6px 14px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 560px) {
  .lead-wrapper {
    &.users {
      flex-flow: column;

      .small {
        width: 100%;
      }
    }
  }
}

@media all and (max-width: 530px) {
  .flex-layout {
    padding: 0;
  }
  .title-block > * {
    display: none;
  }
  .sub-title {
    margin: 0 auto 10px;
    display: block;
  }
}

@media all and (max-width: 460px) {
  body {
    .btn-purple {
      min-width: 50%;
      margin: 0 auto;
    }
    .flex {
      &.wrap {
        flex-flow: row wrap;
      }
    }
  }
  form {
    .form-group {
      width: 100%;
    }
  }
}
