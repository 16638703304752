@font-face {
	font-family: EffraBold;
	src: local("EFFRA_STD_BD"), url("./fonts/EFFRA_STD_BD.TTF") format("truetype");
}
@font-face {
	font-family: EffraItalic;
	src: local("EFFRA_STD_IT"), url("./fonts/EFFRA_STD_IT.TTF") format("truetype");
}
@font-face {
	font-family: EffraLight;
	src: local("EFFRA_STD_LT"), url("./fonts/EFFRA_STD_LT.TTF") format("truetype");
}
@font-face {
	font-family: EffraLightItalic;
	src: local("EFFRA_STD_LTIT"), url("./fonts/EFFRA_STD_LTIT.TTF") format("truetype");
}

@font-face {
	font-family: Effra,sans-serif;
	src: local("EFFRA_STD_RG"), url("./fonts/EFFRA_STD_RG.TTF") format("truetype");
}